import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import github from './images/github.png'
import linkedin from './images/linkedin.png'
import cv from './images/cv.png'
import image1 from './images/pic1.jpg'
import image2 from './images/pic2.jpg'
import image3 from './images/pic3.jpg'
import './App.css';

const images = [image3, image1, image2];

function App() {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  /*const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })*/

  useEffect(() => {
      const interval = setInterval(() => {
          var index = (currentIndex + 1) % 3;
          setCurrentIndex(index);   
          setCurrentImage(images[currentIndex]);
          console.log(index);
      }, 5000)
      
      return () => clearInterval(interval);
  }, [currentIndex])

  const onCVClick = () => {
    const pdfUrl = "MarcusHammarstrom_CV.pdf"
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "MarcusHammarstrom_CV.pdf";
    document.body.appendChild(link);
        link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={isMobile ? "App-mobile" : "App-desktop"}>
      <div className={isMobile ? "Header-mobile" : "Header-desktop"}>
        <div className={isMobile ? "About-mobile" : "About-desktop"}>
          <h3 className={isMobile ? "Title-mobile" : "Title-desktop"}>Hey there!</h3>
          <p className={isMobile ? "Text-mobile" : "Text-desktop"}>My name is Marcus Hammarström and I'm studying computer science at Mälardalen University in Västerås, Sweden.</p>
          <p className={isMobile ? "Text-mobile" : "Text-desktop"}>Some of my areas of interest are software development, embedded systems, machine learning and mathematics. I enjoy learning things and solving problems.</p>
        </div>
        <div>
            <img src={currentImage} className={isMobile ? "Profile-mobile" : "Profile-desktop"} alt="Profile picture"/>
        </div>
      </div>
      <div className={isMobile ? "Content-mobile" : "Content-desktop"}>
        <div className="Content-item">
          <a href="https://github.com/marcusHammarstrom/"><img src={github}/></a>
          <a href="https://github.com/marcusHammarstrom/">GitHub</a>
        </div>
        <div className="Content-item">
          <a href="https://www.linkedin.com/in/marcushammarstr%C3%B6m/"><img src={linkedin}/></a>
          <a href="https://www.linkedin.com/in/marcushammarstr%C3%B6m/">LinkedIn</a>
        </div>
        <div className="Content-item">
          <a href="/" onClick={onCVClick}><img src={cv}/></a>
          <a href="/" onClick={onCVClick}>Download CV</a>
        </div>
      </div>
      <a className={isMobile ? "Email-mobile" : "Email-desktop"} href="mailto:contact@marcushammarstrom.com">contact@marcushammarstrom.com</a>
    </div>
  );
}

export default App;
